import React from "react";
import styles from "../../../pages/styles";
import { zoomcall, logowithclouds } from "../../../assets/images";
import { Parallax } from "react-scroll-parallax";

const Instructors = () => {
  const instructors = [
    {
      name: "Skyler Cameron",
      image: logowithclouds,
      role: "Full Stack Development Expert",
      points: [
        "💻 Full Stack Developer 10+ Years",
        "🎓 Multiple Programming Certifications",
        "🕹️ Published Game Developer",
        "🤖 Senior Development Roles"
      ]
    },
    {
      name: "Evan Stern",
      image: logowithclouds,
      role: "Senior Game Developer",
      points: [
          "🦸 12+ Years Industry Experience",
          "📈 Senior Development Roles",
          "🏰 Disney Marvel Projects",
        "🎬 Warner Bros Game Developer"
      ]
    },
    {
      name: "Jacob Bloch",
      image: logowithclouds,
      role: "Game Development Instructor",
      points: [
          "🎮 2D Game Development Specialist",
          "📚 Longtime Game Dev Instructor",
          "🕹️ Multiple Published Games",
          "✏️ Certified Teaching Experience"
      ]
    },
    {
      name: "Austin Weeks",
      image: logowithclouds,
      role: "Unity & AI Specialist",
      points: [
        "🕹️ Unity AI Engineer",
        "🌐 Certified Website Developer",
        "⚓ 'Seas of Rebellion' Creator",
        "🎓 Game Design Instructor"
      ]
    }
  ];

  return (
    <div className="bg-whiteprimary relative py-8 md:py-12 lg:px-32 px-4 sm:px-8 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12 md:mb-20 relative z-20">
          <h1 className={`${styles.headingdark} mb-3 text-darkprimary`}>Meet Your Tech Mentors</h1>
          <p className={`${styles.paragraph} text-lg md:text-xl text-darkprimary/80`}>
            Industry professionals turned passionate educators
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 relative z-20">
          {instructors.map((instructor, index) => (
            <div 
              key={index}
              className="group bg-white rounded-2xl md:rounded-3xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border-2 border-tanprimary"
            >
              <div className="relative -mt-14 mb-6">
                <Parallax speed={-5}>
                    <div className="w-24 h-24 md:w-32 md:h-32 rounded-full border-4 border-tanprimary shadow-md mx-auto overflow-hidden bg-darkprimary flex items-center justify-center">
                        <img 
                            src={instructor.image} 
                            alt={instructor.name}
                            className="w-full h-full object-contain p-1 pl-2"
                        />
                    </div>
                </Parallax>
                <div className="absolute inset-0 flex items-end justify-center">
                  <div className="w-28 h-28 md:w-36 md:h-36 rounded-full bg-blueprimary/20 blur-xl" />
                </div>
              </div>

              <div className="text-center mb-6">
                <h3 className={`${styles.headingdark} text-xl md:text-2xl font-bold text-darkprimary mb-1`}>{instructor.name}</h3>
                <p className={`${styles.paragraph} `}>{instructor.role}</p>
              </div>

              <ul className="space-y-2 md:space-y-3 px-1 pb-1">
                {instructor.points.map((point, i) => (
                  <li 
                    key={i}
                    className="flex items-start bg-tanprimary rounded-lg md:rounded-xl p-2 md:p-3"
                  >
                    <span className={`${styles.paragraph} text-sm md:text-base leading-snug`}>{point}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <Parallax speed={10} className="absolute top-1/4 -right-40 opacity-30 rotate-12">
          <div className="w-96 h-96 bg-pinkprimary/30 rounded-full blur-3xl" />
        </Parallax>
      </div>
    </div>
  );
};

export default Instructors;