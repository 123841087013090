import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import { aboutfox } from "../../assets/images";
import useOnboardingForm from "../../components/Home/hooks/useOnboardingForm";
import { HelpCircle } from "lucide-react";
import styles from "../styles";

const darkprimary = "#141414";
const whiteprimary = "#ffffff";
const pinkprimary = "#F290BB";

const textFieldSX = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: whiteprimary,
    "& fieldset": {
      borderColor: darkprimary,
    },
    "&.Mui-focused fieldset": {
      borderColor: darkprimary,
    },
    "& input": {
      color: darkprimary,
      padding: "17px",
      fontFamily: styles.fontFamily,
    },
  },
  "& .MuiInputLabel-root": {
    color: darkprimary,
    fontFamily: styles.fontFamily,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: darkprimary,
  },
};

const InfoIcon = ({ message }) => {
  return (
    <Tooltip title={message} arrow>
      <HelpCircle className="w-5 h-5 text-darkprimary cursor-pointer" />
    </Tooltip>
  );
};

const StandaloneTrialForm = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [formData, setFormData] = useState({
    studentFirstName: "",
    studentLastName: "",
    studentAge: "",
    studentEmail: "",
    guardianName: "",
    phoneNumber: "",
    emergencyContact: "",
    emergencyContactNumber: "",
    programInterested: "",
    learningNeeds: "",
    studentInterest: "",
    otherInterest: "",
    // Two separate fields for scholarship:
    scholarshipSelect: "No", // Value from the dropdown
    scholarshipOther: "", // Custom input when "Other" is selected
  });

  const availableInterests = [
    "Gaming",
    "Coding",
    "Game Design",
    "Roblox Studio",
    "Roblox Scripting",
    "Minecraft Modding",
    "Drawing",
    "Movies",
    "Art",
    "Programming",
    "Other",
  ];
  const [selectedInterests, setSelectedInterests] = useState([]);

  const { isLoading, submitForm } = useOnboardingForm();
  const [userTimeZone, setUserTimeZone] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setUserTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    setCurrentUrl(window.location.href);
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const formatPhoneNumber = (val) => {
    const numbers = val.replace(/\D/g, "");
    const char = { 0: "(", 3: ") ", 6: "-" };
    return numbers
      .substring(0, 10)
      .split("")
      .map((n, i) => (char[i] || "") + n)
      .join("");
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const formatted = formatPhoneNumber(value);
    setFormData((prev) => ({ ...prev, [name]: formatted }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleInterest = (interest) => {
    // For "Other", once selected, do not allow toggling it off.
    if (interest === "Other") {
      if (!selectedInterests.includes(interest)) {
        setSelectedInterests([...selectedInterests, interest]);
      }
      return;
    }
    setSelectedInterests((current) => {
      if (current.includes(interest)) {
        return current.filter((item) => item !== interest);
      }
      return [...current, interest];
    });
  };

  const handleScholarshipSelectChange = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({
      ...prev,
      scholarshipSelect: value,
      // Clear the custom field if a non-"Other" option is chosen.
      scholarshipOther: value === "Other" ? prev.scholarshipOther : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionError(null);
    if (!isValidEmail(formData.studentEmail)) {
      alert("Please enter a valid email address.");
      return;
    }
    const baseInterests = selectedInterests
      .filter((interest) => interest !== "Other")
      .join(", ");
    const finalInterests =
      selectedInterests.includes("Other") && formData.otherInterest
        ? `${baseInterests}, ${formData.otherInterest}`
        : baseInterests;
    const timestamp = new Date();
    // Build submission data including both scholarship fields.
    const submissionData = {
      ...formData,
      scholarshipName: formData.scholarshipSelect,
      scholarshipOther:
        formData.scholarshipSelect === "Other" ? formData.scholarshipOther : "",
      studentInterest: finalInterests,
      selectedDates: "",
      selectedTimes: "",
      timestamp,
      userTimeZone,
      submittedFromUrl: currentUrl,
    };
    try {
      const documentId = await submitForm(submissionData);
      if (!documentId) {
        throw new Error("Submission failed");
      }
      setIsSubmitted(true);
    } catch (err) {
      setSubmissionError(err);
    }
  };

  return (
    <div className="flex flex-col items-center relative">
      <div className="w-full max-w-5xl pt-6 pb-6 rounded-t-[4rem]">
        {!isSubmitted ? (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col mx-auto gap-5 sm:px-14 p-5 bg-whiteprimary rounded-3xl"
          >
            {/* Student Details */}
            <div>
              <h2
                className={`${styles.headingdark} text-gray-800 text-xl font-semibold mb-4`}
              >
                Student Details
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  label="Student First Name"
                  variant="outlined"
                  fullWidth
                  name="studentFirstName"
                  value={formData.studentFirstName}
                  onChange={handleChange}
                  required
                  sx={textFieldSX}
                />
                <TextField
                  label="Student Last Name"
                  variant="outlined"
                  fullWidth
                  name="studentLastName"
                  value={formData.studentLastName}
                  onChange={handleChange}
                  required
                  sx={textFieldSX}
                />
                <TextField
                  label="Student Age"
                  variant="outlined"
                  fullWidth
                  name="studentAge"
                  value={formData.studentAge}
                  onChange={handleChange}
                  required
                  sx={textFieldSX}
                />
                <TextField
                  label="Student Email"
                  variant="outlined"
                  fullWidth
                  name="studentEmail"
                  value={formData.studentEmail}
                  onChange={handleChange}
                  required
                  sx={textFieldSX}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="We will send the link for virtual class to this email, put any email your student has access to."
                          arrow
                        >
                          <HelpCircle className="w-5 h-5 text-darkprimary" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>

            {/* Guardian Details */}
            <div>
              <h2
                className={`${styles.headingdark} text-gray-800 text-xl font-semibold mb-4`}
              >
                Guardian Details
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  label="Parent/Guardian Full Name"
                  variant="outlined"
                  fullWidth
                  name="guardianName"
                  value={formData.guardianName}
                  onChange={handleChange}
                  required
                  sx={textFieldSX}
                />
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  required
                  sx={textFieldSX}
                  inputProps={{ maxLength: 14 }}
                />
              </div>
            </div>

            {/* Student Interests */}
            <div>
              <h2
                className={`${styles.headingdark} text-gray-800 text-xl font-semibold mb-2`}
              >
                Student Interests
                <span className="block text-base font-normal">
                  What is Your Student Interested In?
                </span>
              </h2>
              <div className="flex flex-wrap gap-2">
                {availableInterests.map((interest) => (
                  <button
                    key={interest}
                    type="button"
                    onClick={() => toggleInterest(interest)}
                    className={`px-3 py-2 rounded-full border-2 ${
                      selectedInterests.includes(interest)
                        ? "bg-gray-800 border-gray-800 text-white font-semibold"
                        : "bg-white border-gray-800 text-gray-800 font-semibold"
                    } focus:outline-none cursor-pointer`}
                  >
                    {interest}
                  </button>
                ))}
                {selectedInterests.includes("Other") && (
                  <TextField
                    label="Specify Other Interest"
                    variant="outlined"
                    fullWidth
                    name="otherInterest"
                    value={formData.otherInterest}
                    onChange={handleChange}
                    required
                    sx={textFieldSX}
                  />
                )}
              </div>
            </div>

            {/* Scholarship Section */}
            <div>
              <h2
                className={`${styles.headingdark} text-gray-800 text-xl font-semibold mb-4`}
              >
                Scholarship Payment
              </h2>
              <div className="flex flex-col gap-3">
                <TextField
                  select
                  label="Scholarship?"
                  variant="outlined"
                  fullWidth
                  name="scholarshipSelect"
                  value={formData.scholarshipSelect}
                  onChange={handleScholarshipSelectChange}
                  sx={textFieldSX}
                  SelectProps={{ native: true }}
                >
                  <option value="No">No Scholarship</option>
                  <option value="Utah Fits All">Utah Fits All</option>
                  <option value="OpenEd">OpenEd</option>
                  <option value="ESA Arizona">ESA Arizona</option>
                  <option value="StepUpFlorida">StepUpFlorida</option>
                  <option value="Other">Other</option>
                </TextField>
                {formData.scholarshipSelect === "Other" && (
                  <TextField
                    label="Enter Scholarship Name"
                    variant="outlined"
                    fullWidth
                    name="scholarshipOther"
                    value={formData.scholarshipOther}
                    onChange={handleChange}
                    required
                    sx={textFieldSX}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="mt-4 bg-pinkprimary text-whiteprimary text-lg px-6 py-2 rounded-xl font-semibold focus:outline-none cursor-pointer"
              disabled={isLoading}
            >
              Submit Application
            </button>
            {submissionError && (
              <div className="mt-2 text-red-500 text-sm">
                {submissionError.message ||
                  JSON.stringify(submissionError) ||
                  "Submission failed. Please try again."}
              </div>
            )}
            <div className={`${styles.paragraph} text-xs text-left`}>
              By clicking submit, you agree to our{" "}
              <a href="/pages/termsofservice" className="text-pinkprimary hover:underline">
                Terms of Service
              </a>
              .
            </div>
          </form>
        ) : (
          <div className="flex flex-col sm:flex-row justify-evenly items-center sm:px-14 p-5 bg-whiteprimary rounded-3xl gap-4">
            <div>
              <h2 className={`${styles.headingdark} text-center mb-2`}>
                Welcome to the Tech Tails Family!
              </h2>
              <p className={`${styles.paragraph} text-center`}>
                We will be in touch soon.
              </p>
            </div>
            <img src={aboutfox} className="w-[25%] min-w-[240px]" alt="Fox" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StandaloneTrialForm;
