import React from "react";
import {
  About,
  FAQ,
  Hero,
  ContactUs,
  OurApproach,
  OurClasses,
  TryBefore,
  EmailDiscount,
  FreeTrialAbout,
  FreeTrialHero,
  FreeTrialSkills,
  FreeTrialPrograms,
} from "../../components/index";
import { Helmet } from "react-helmet";
import { freetrialhero } from "../../assets/images";
import InstructorExperience from "./InstructorExperience";
import Reviews from "../../components/Home/layout/Reviews";
import ReviewsCompact from "../../components/Home/layout/ReviewsCompact";
import StandaloneTrialForm from "./StandaloneTrialForm";
import styles from "../styles";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>
          Discover Tech Tails: Personalized Tech Education from Salt Lake City
        </title>
        <meta
          name="description"
          content="Embark on an epic tech learning adventure with Tech Tails. Based in Salt Lake City, Utah, we bring engaging, personalized tech education to your doorstep. Unlock your potential and explore the world of technology with our virtual classes. Join the community of future tech wizards today!"
        />
        <meta
          name="keywords"
          content="Tech Tails, tech education, virtual tech classes, learn technology, Salt Lake City tech, personalized tech learning, tech wizards"
        />
      </Helmet>

      <FreeTrialHero
        title="Navigator"
        desc="A Tech Tails Program designed for individuals of all abilities, with trained instructors and personalized classes designed to align with each student's interests, fostering a unique and engaging learning experience."
        img={freetrialhero}
      />
      <InstructorExperience/>
      <FreeTrialSkills />
      <ReviewsCompact/>
      <FreeTrialAbout />
      <FreeTrialPrograms />
      {/* <TryBefore /> */}
      <div
        id="trialClass"
        className="scroll-mt-20 flex flex-col py-14 gap-3 pt-12 md:px-40 px-4 items-center bg-darkprimary"
      >
        <h1 className={`${styles.biggerheadingwhite} text-center`}>
          Try Before{" "}
          <span className="underline decoration-pinkprimary decoration-8">
            Anything!
          </span>
        </h1>
        <p className={`${styles.paragraphwhite} text-center`}>
          We're confident if your child has an interest in anything tech related, they will love class. 
          Experience it with a free trial class, completely
          risk-free.
        </p>
        <StandaloneTrialForm/>
      </div>
      <EmailDiscount/>
      <FAQ />
    </div>
  );
};

export default Home;
