import React from "react";
import styles from "../../pages/styles";

const PricingClasses = ({ title, desc }) => {
  const pricingData = [
    {
      title: "Discounted Commitment Plans",
      startingPrice: "$240/month",
      features: [
        "Discounted for commitment",
        "Weekly 1:1 Classes with an Expert",
        "1:1 STEM through Game Development",
        "Includes homework & software fees",
      ],
    },
    {
      title: "Month-to-Month Plans",
      startingPrice: "$260/month",
      features: [
        "Flexible scheduling",
        "Weekly 1:1 Classes with an Expert",
        "No long-term commitment",
        "Includes homework & software fees",
      ],
    },
  ];

  return (
    <div className="py-16 px-4 md:px-20 bg-blueprimary">
      <div className="max-w-4xl mx-auto text-center mb-12">
        <h2 className={`${styles.biggerheadingdark} mb-4`}>{title}</h2>
        <p className={`${styles.paragraph}`}>{desc}</p>
      </div>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {pricingData.map((plan, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg border border-gray-200 p-8 flex flex-col justify-between transition transform hover:scale-105"
          >
            <h3 className={`${styles.headingdark} text-center mb-6`}>
              {plan.title}
            </h3>
            <div className="text-center mb-6">
              <p className="text-sm text-gray-600">Starting at</p>
              <span className="text-3xl font-bold text-pinkprimary">
                {plan.startingPrice}
              </span>
            </div>
            <ul className="list-disc list-inside text-gray-700 mb-6">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="mb-2">{feature}</li>
              ))}
            </ul>
            <div className="text-center">
              <a
                href="/pages/freetrial"
                className={`${styles.paragraphwhite} bg-pinkprimary text-white px-6 py-3 rounded-full inline-block focus:outline-none focus:ring-4 hover:bg-orangeprimary`}
              >
                Schedule Trial Class
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingClasses;
