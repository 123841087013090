import React, { useState } from "react";
import styles from "../styles";
import { aboutfox, textlogo } from "../../assets/images";
import useOnboardingForm from "../../components/Home/hooks/useOnboardingForm";
import { Helmet } from "react-helmet";
import { HelpCircle } from "lucide-react";

const InfoIcon = ({ message }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <div className="relative flex items-center justify-center">
      <HelpCircle
        onMouseEnter={() => setShowOverlay(true)}
        onMouseLeave={() => setShowOverlay(false)}
        onClick={() => setShowOverlay(!showOverlay)}
        className="ml-2 w-5 h-5 text-darkprimary hover:text-pinkprimary cursor-pointer"
      />
      {showOverlay && (
        <div className="absolute w-48 p-2 bg-white shadow-md rounded-lg bottom-5 right-2">
          <p className={`${styles.paragraph} text-sm leading-normal`}>
            {message}
          </p>
        </div>
      )}
    </div>
  );
};

const StudentOnboard = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEmergencyContactSame, setIsEmergencyContactSame] = useState(false);

  const [formData, setFormData] = useState({
    studentFirstName: "",
    studentLastName: "",
    studentAge: "",
    studentEmail: "",
    guardianName: "",
    phoneNumber: "",
    emergencyContact: "",
    emergencyContactNumber: "",
    programInterested: "",
    learningNeeds: "",
    studentInterest: "",
    otherInterest: "",
  });

  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState({});

  const handleTimeSelection = (dateString, time) => {
    setSelectedTimes({
      ...selectedTimes,
      [dateString]: [time],
    });
  };

  const renderTimeOptions = (dateString) => {
    const timeOptions = [
      "8AM-10AM",
      "10AM-12PM",
      "12PM-2PM",
      "2PM-4PM",
      "4PM-6PM",
    ];

    return (
      <select
        className="px-1.5 py-1.5 rounded-full border-2 min-w-24 bg-white border-orangeprimary text-darkprimary focus:outline-none"
        value={selectedTimes[dateString] || ""}
        onChange={(e) => handleTimeSelection(dateString, e.target.value)}
      >
        <option value="">Select Time</option>
        {timeOptions.map((time) => (
          <option key={time} value={time} required>
            {time}
          </option>
        ))}
      </select>
    );
  };

  const [selectedDates, setSelectedDates] = useState([]);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() + 1); // Adjusted from +0 to +2
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 13);

  const getDateArray = (start, end) => {
    const arr = [];
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      if (dt.getDay() !== 0) {
        // Exclude Sundays
        arr.push(
          `${dt.toDateString().split(" ")[0]} ${
            dt.getMonth() + 1
          }/${dt.getDate()}`
        );
      }
    }
    return arr;
  };

  const availableDates = getDateArray(startDate, endDate);

  // Format the date range for display in the title
  const formattedStartDate = `${
    startDate.getMonth() + 1
  }/${startDate.getDate()}`;
  const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getDate()}`;

  const availableInterests = [
    "Gaming",
    "Coding",
    "Drawing",
    "Story Telling",
    "Movies",
    "Animation",
    "Programming",
    "Video Editing",
    "Website Development",
    "2D Digital Art",
    "Computer Building",
    "Other",
  ];

  const { isLoading, error, submitForm } = useOnboardingForm();

  const toggleInterest = (interest) => {
    setSelectedInterests((current) => {
      if (current.includes(interest)) {
        return current.filter((item) => item !== interest);
      } else {
        return [...current, interest];
      }
    });
  };

  const handleDateClick = (dateString) => {
    // Check if the date is already selected
    if (selectedDates.includes(dateString)) {
      // If so, remove it from the array
      setSelectedDates(selectedDates.filter((date) => date !== dateString));
      // Also remove any selected times for that date
      const { [dateString]: removed, ...rest } = selectedTimes;
      setSelectedTimes(rest);
    } else {
      // Otherwise, add the new date to the array, with a check to ensure no more than 4 dates are selected
      if (selectedDates.length < 4) {
        setSelectedDates([...selectedDates, dateString]);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedDates.length < 2 || selectedTimes.length < 2) {
      alert("Please select at least two available dates for the trial.");
      return;
    }

    let allTimesSelected = true;
    for (let date of selectedDates) {
      if (
        !selectedTimes[date] ||
        selectedTimes[date].length === 0 ||
        selectedTimes[date][0] === ""
      ) {
        allTimesSelected = false;
        break;
      }
    }

    if (!allTimesSelected) {
      alert("Please select a specific time for each chosen date.");
      return;
    }

    const baseInterests = selectedInterests
      .filter((interest) => interest !== "Other")
      .join(", ");
    const finalInterests =
      selectedInterests.includes("Other") && formData.otherInterest
        ? `${baseInterests}, ${formData.otherInterest}`
        : baseInterests;

    const selectedDatesTimes = Object.keys(selectedTimes)
      .map((dateString) => {
        return `${dateString}: ${selectedTimes[dateString]}`;
      })
      .join(", ");

    const timestamp = new Date();

    const submissionData = {
      ...formData,
      studentInterest: finalInterests,
      selectedDates: selectedDatesTimes,
      timestamp: timestamp,
    };

    setIsSubmitted(true);

    const documentId = await submitForm(submissionData);

    if (documentId) {
      console.log("Form submitted successfully", documentId);
    }
  };

  const programOptions = [
    { value: "PrivateClassCourse", label: "Private Class 1:1 (Course)" },
    {
      value: "PrivateClassPersonalized",
      label: "Private Class 1:1 (Personalized) | Most Popular",
    },
    { value: "GroupClass", label: "Group Classes" },
  ];

  return (
    <div className="flex flex-col items-center relative">
      <img
        src={textlogo}
        alt="Tech Tails"
        className="w-[20%] min-w-[170px] pt-4"
      />
      <h2 className={`${styles.biggerheadingdark} mt-4 pb-10 text-center px-1`}>
        Student Trial Form
      </h2>
      <div className="bg-orangeprimary w-full pt-10 pb-32 sm:px-20 px-4 rounded-t-[4rem]">
        <h2 className={`${styles.headingdark} mb-5 text-center`}>
          Help us create the perfect tech adventure for your child 🚀
        </h2>
        {!isSubmitted ? (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col mx-auto gap-5 sm:px-14 p-5 bg-whiteprimary rounded-3xl max-w-4xl"
          >
            <div className="grid sm:grid-cols-2 grid-cols-2 gap-4 items-end">
              <div>
                <h2
                  className={`${styles.paragraph} text-xl font-semibold mb-2`}
                >
                  Student Details
                </h2>
                <TextInput
                  title="Student First Name"
                  name="studentFirstName"
                  placeholder="Jack"
                  value={formData.studentFirstName}
                  onChange={handleChange}
                />
              </div>
              <TextInput
                title="Student Last Name"
                name="studentLastName"
                placeholder="Doe"
                value={formData.studentLastName}
                onChange={handleChange}
              />
              <TextInput
                title="Student Age"
                name="studentAge"
                placeholder="Age"
                value={formData.studentAge}
                onChange={handleChange}
              />
              <TextInput
                title="Students Email"
                name="studentEmail"
                placeholder="We will send the link for class here"
                value={formData.studentEmail}
                onChange={handleChange}
                infoMessage="We will send the link for virtual class to this email, put any email your student has access to."
              />
            </div>
            <h2
              className={`${styles.paragraph} text-xl font-semibold -mb-2 mt-2`}
            >
              Guardian Details
            </h2>
            <div className="grid sm:grid-cols-2 grid-cols-2 gap-4 items-end">
              <TextInput
                title="Parent/Guardian Name"
                name="guardianName"
                placeholder="John Doe"
                value={formData.guardianName}
                onChange={handleChange}
              />
              <NumberInput
                title="Phone Number"
                name="phoneNumber"
                placeholder="(123) 456-7891"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="flex items-center -mb-2">
              <input
                type="checkbox"
                id="sameAsParent"
                checked={isEmergencyContactSame}
                onChange={(e) => setIsEmergencyContactSame(e.target.checked)}
                className="w-4 h-4"
              />
              <label
                htmlFor="sameAsParent"
                className={`${styles.paragraph} text-lg ml-2`}
              >
                Emergency contact is the same as parent/guardian
              </label>
            </div>
            {!isEmergencyContactSame && (
              <div className="grid sm:grid-cols-2 grid-cols-2 gap-4 items-end">
                <TextInput
                  title="Emergency Contact"
                  name="emergencyContact"
                  placeholder="Jane Doe"
                  value={formData.emergencyContact}
                  onChange={handleChange}
                />
                <NumberInput
                  title="Emergency Phone #"
                  name="emergencyContactNumber"
                  placeholder="(123) 456-7891"
                  value={formData.emergencyContactNumber}
                  onChange={handleChange}
                />
              </div>
            )}
            <h2
              className={`${styles.paragraph} text-xl font-semibold -mb-2 mt-2`}
            >
              Program Questions
            </h2>
            <div>
              <p className={`${styles.paragraph} mb-2`}>
                What is your student interested in?
              </p>
              <div className="flex flex-wrap gap-2">
                {availableInterests.map((interest) => (
                  <button
                    key={interest}
                    type="button"
                    onClick={() => toggleInterest(interest)}
                    className={`px-3 py-2 rounded-full border-2 ${
                      selectedInterests.includes(interest)
                        ? "bg-orangeprimary border-orangeprimary text-white"
                        : "bg-white border-orangeprimary text-darkprimary"
                    } focus:outline-none`}
                  >
                    {interest}
                  </button>
                ))}
                <div>
                  {selectedInterests.includes("Other") && (
                    <TextInput
                      name="otherInterest"
                      placeholder="Specify other interest"
                      value={formData.otherInterest}
                      onChange={handleChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4 items-end">
              <SelectInput
                title="Program Interested In"
                name="programInterested"
                value={formData.programInterested}
                onChange={handleChange}
                options={programOptions}
              />
            </div>
            <TextAreaInput
              title="What Learning Needs Can We Accommodate For Your Child?"
              name="learningNeeds"
              placeholder="How does your child learn best?"
              value={formData.learningNeeds}
              onChange={handleChange}
            />
            <div>
              <h2 className={`${styles.paragraph}`}>
                Select Up to 4 Available Dates For Trial (Next 2 Weeks:{" "}
                {formattedStartDate}-{formattedEndDate})
              </h2>
              <div className="flex flex-wrap gap-2 justify-center">
                {availableDates.map((dateString, index) => (
                  <button
                    key={index}
                    type="button"
                    className={`px-4 py-2 rounded-full border-2 min-w-24 ${
                      selectedDates.includes(dateString)
                        ? "bg-orangeprimary border-orangeprimary text-white"
                        : "bg-white border-orangeprimary text-darkprimary"
                    } focus:outline-none`}
                    onClick={() => handleDateClick(dateString)}
                  >
                    {dateString}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex flex-wrap gap-x-5 justify-center">
              {selectedDates.map((selectedDate, index) => (
                <div key={index} className="mt-4 text-center">
                  <div>
                    <h3 className={`${styles.paragraph}`}>
                      Times for {selectedDate}
                    </h3>
                    <div>{renderTimeOptions(selectedDate)}</div>
                  </div>
                </div>
              ))}
            </div>

            <button
              type="submit"
              className="mt-4 bg-pinkprimary text-whiteprimary text-lg px-6 py-2 rounded-xl hover:bg-orangeprimary"
              disabled={isLoading}
            >
              Submit Application
            </button>
            <div className={`${styles.paragraph} text-xs text-left`}>
              By clicking submit, you agree to our{" "}
              <a
                href="/pages/termsofservice"
                className="text-pinkprimary hover:underline"
              >
                Terms of Service
              </a>
              .
            </div>
            {error && (
              <p className="text-red-500">
                Error submitting form: {error.message}
              </p>
            )}
          </form>
        ) : (
          <div className="flex sm:flex-row flex-col sm:justify-evenly justify-center items-center sm:px-14 p-5 bg-whiteprimary rounded-3xl gap-4">
            <div>
              <h2 className={`${styles.headingdark} text-center mb-2`}>
                Welcome to the Tech Tails Family!
              </h2>
              <p className={`${styles.paragraph} text-center`}>
                We will be in touch soon.
              </p>
            </div>
            <img src={aboutfox} className="w-[25%] min-w-[240px]" />
          </div>
        )}
      </div>
    </div>
  );
};

const TextInput = ({
  name,
  placeholder,
  title,
  value,
  onChange,
  infoMessage,
}) => (
  <div className="w-full flex justify-between items-center">
    <div className="flex-1">
      <p className={`${styles.paragraph} mb-0 flex items-center`}>
        {title}
        <span> {infoMessage && <InfoIcon message={infoMessage} />}</span>
      </p>
      <input
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full rounded-xl border-orangeprimary border-2 px-4 py-2 focus:outline-none focus:border-orangeprimary"
        required
      />
    </div>
  </div>
);

const TextAreaInput = ({
  name,
  placeholder,
  title,
  value,
  onChange,
  infoMessage,
}) => (
  <div className="w-full flex justify-between items-center">
    <div className="flex-1">
      <p className={`${styles.paragraph} mb-0 flex items-center`}>
        {title} {infoMessage && <InfoIcon message={infoMessage} />}
      </p>
      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full rounded-xl border-orangeprimary border-2 px-4 py-2 focus:outline-none focus:border-orangeprimary"
        style={{ minHeight: "2.375rem", resize: "vertical" }} // Allows vertical resizing
        required
      />
    </div>
  </div>
);

const NumberInput = ({
  name,
  placeholder,
  title,
  value,
  onChange,
  infoMessage,
}) => {
  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/\D/g, "");
    const char = { 0: "(", 3: ") ", 6: "-" };
    const phoneNumber = numbers
      .substring(0, 10)
      .split("")
      .map((n, i) => (char[i] || "") + n)
      .join("");
    return phoneNumber;
  };

  const handleInputChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    onChange({ target: { name: e.target.name, value: formattedPhoneNumber } });
  };

  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex-1">
        <p className={`${styles.paragraph} mb-0 flex items-center`}>
          {title} {infoMessage && <InfoIcon message={infoMessage} />}
        </p>
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          className="w-full rounded-xl border-orangeprimary border-2 px-4 py-2 focus:outline-none focus:border-orangeprimary"
          required
        />
      </div>
    </div>
  );
};

const SelectInput = ({
  name,
  value,
  onChange,
  options,
  title,
  infoMessage,
}) => (
  <div className="w-full flex justify-between items-center">
    <div className="flex-1">
      <p className={`${styles.paragraph} mb-0`}>{title}</p>
      <select
        name={name}
        value={value}
        onChange={onChange}
        className="p-2 px-4 rounded-xl w-full bg-white border-orangeprimary border-2 focus:border-orangeprimary focus:outline-none"
        required
      >
        <option value="" disabled>
          Please Choose
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
    {infoMessage && <InfoIcon message={infoMessage} />}
  </div>
);
export default StudentOnboard;
