import React from "react";
import { FreeTrial } from "../../components/index";
import { ContactUs } from "../../components/index";
import { Helmet } from "react-helmet";
import TrialForm from "./TrialForm";
import StandaloneTrialForm from "./StandaloneTrialForm";

const FreeTrialPage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Free Trial Class: Experience Personalized Tech Learning | Tech Tails
        </title>
        <meta
          name="description"
          content="Sign up for a free 1-hour trial class with our expert instructors! No pressure, just a fun and engaging tech learning experience for your child."
        />
        <meta
          name="keywords"
          content="Tech Tails free trial, free coding class, tech education trial, game design trial class, virtual coding lessons, personalized tech learning, STEM trial course"
        />
      </Helmet>

      <FreeTrial />
      <div className="bg-darkprimary">
      <StandaloneTrialForm/>

      </div>
    </div>
  );
};

export default FreeTrialPage;
