import React from "react";
import styles, { elements } from "../../pages/styles";
import { TypeAnimation } from "react-type-animation";
import { freetrialhero, freetrialheromobile, teacherzoom, textlogo } from "../../assets/images";
import { X } from "lucide-react";

const FreeTrialHero = ({ title, desc, img, bgcolor }) => {
  return (
    <div className="flex flex-col md:flex-row items-stretch w-full rounded-t-xl relative -mt-4 md:-mt-14">
        
    {/* LEFT SECTION (Hero Images) */}
    <div className="relative w-full md:w-1/2">
      {/* Desktop hero (hidden on mobile) */}
      <img
        src={freetrialhero}
        alt="Free Trial Hero (Desktop)"
        className="
          hidden 
          md:block 
          w-full 
          object-cover
          md:min-h-[700px]  /* enforce a decent desktop height */
        "
      />
      {/* Mobile hero (hidden on desktop) */}
      <img
        src={freetrialheromobile}
        alt="Free Trial Hero (Mobile)"
        className="
          block
          md:hidden
          w-full
          h-auto
          object-cover
        "
      />

      {/* Teacher Zoom (absolutely positioned to overlap) */}
      <img
        src={teacherzoom}
        alt="Teacher Zoom"
        className="
          teacherZoomImg
          zoomImg
          md:w-60
          w-40
          absolute              
          transform
        "
      />
    </div>

    {/* RIGHT SECTION (Logos, Heading, CTA) */}
    <div
      className="
        w-full 
        md:w-1/2
        px-4
        pb-6
        flex 
        flex-col 
        items-center 
        md:items-start 
        justify-center 
        text-center 
        md:text-left
      "
    >
      {/* Desktop-only Logos */}
      <div className="flex items-center py-6 space-x-2">
        <img
          src={textlogo}
          alt="Tech Tails Logo"
          className="
            w-auto 
            h-auto
            min-w-[100px]    
            md:min-w-[100px]    
          "
        />
      </div>

      <h1 className={`${styles.biggerheadingdark} mb-4`}>
        Unlock Your Child's
        <br />
        <span className="underline decoration-pinkprimary decoration-[6px]">
          Potential
          <br />
        </span>
        In{" "}
        <TypeAnimation
          repeat={Infinity}
          speed={5}
          sequence={[
            "Technology",
            2000,
            "Programming",
            2000,
            "Creativity",
            2000,
            "Learning",
            2000,
            "Game Design",
            2000,
            "Coding",
            1500,
            "Digital Art",
            2000,
          ]}
        />
      </h1>

      <p className={`${styles.paragraph} mb-6`}>
        Replace hours of screen time with a valuable learning adventure
        making a video game! 🚀 Think of us as virtual technology guides
        👨‍💻
      </p>

      {/*
        CHANGED: Use an anchor link so that clicking it scrolls to the
        "Try Before Anything!" section below instead of going to /pages/freetrial
      */}
      <a
        className={`${elements.bluebutton} md:w-fit w-full`}
        href="#trialClass"
      >
        Request Trial Class
      </a>
    </div>
  </div>
  );
};

export default FreeTrialHero;
