import React from "react";
import styles from "../../pages/styles";
import { zoomcall } from "../../assets/images";

const skills = [
  "Game Design",
  "Coding",
  "3D Art",
  "Video Editing",
  "Website Development",
  "Roblox Coding",
  "App Development",
  "Minecraft Modding",
  "and so many more...",
];

const FreeTrialSkills = () => {
  return (
<div className="bg-whiteprimary mx-auto pt-8 sm:pt-10 flex flex-col items-center lg:px-24 md:px-10 px-4">
      <div className="flex flex-col md:flex-row gap-6 mb-4 items-center justify-evenly w-full">

        {/* Text and Skills Section */}
        <div className="flex flex-col items-center md:items-start md:w-1/2">
          <h2 className={`${styles.biggerheadingdark} mb-4 text-left`}>
            What We Teach
          </h2>
          <p className={`${styles.paragraph} mb-4 text-center md:text-left`}>
            We’re here to guide your child on a learning adventure with expertise,
            offering lessons across all technology topics.
          </p>
          <div className="flex flex-wrap gap-4 mb-4 justify-center md:justify-start">
            {skills.map((skill, index) => (
              <Bubble key={index} text={skill} />
            ))}
          </div>
        </div>
        {/* Image Section */}
        <div className="flex-shrink-1">
          <img src={zoomcall} alt="Zoom Call" className="rounded-3xl" />
        </div>
      </div>
    </div>
  );
};

const Bubble = ({ text }) => {
  const style = {
    "--animation-duration-y": `${4 + Math.random() * 3}s`, // Between 4 and 7 seconds
    "--translate-y": `${3 + Math.random() * 20}px`,       // Between 3px and 23px
  };

  return (
    <div
      className="bubble bg-orangeprimary rounded-full p-4 text-center shadow-lg"
      style={style}
    >
      <p className={`${styles.paragraph}`}>{text}</p>
    </div>
  );
};

export default FreeTrialSkills;
