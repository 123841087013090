import React from "react";
import {
  About,
  FAQ,
  Hero,
  ContactUs,
  OurApproach,
  OurClasses,
  TryBefore,
  EmailDiscount,
  FreeTrialAbout,
  FreeTrialHero,
  FreeTrialSkills,
  FreeTrialPrograms,
} from "../../components/index";
import { Helmet } from "react-helmet";
import { freetrialhero } from "../../assets/images";
import Instructors from "../../components/Home/layout/Instructors";
import Reviews from "../../components/Home/layout/Reviews";

const AboutUs = () => {
  return (
    <div>
      {" "}
      <Helmet>
        <title>
          About Tech Tails: Personalized Tech Education from the Experts | Tech Tails
        </title>
        <meta
          name="description"
          content="Our expert-led virtual classes make coding, game design, and video editing fun and personal. Join families who are transforming their tech journey with us today!"
        />
        <meta
          name="keywords"
          content="Tech Tails, about tech tails, programming for kids, tech education, virtual tech classes, learn technology, Salt Lake City tech, personalized tech learning, tech wizards"
        />
      </Helmet>
      <Hero />
      <About
        title="About "
        desc="We're Tech Tails from Salt Lake City, Utah 🏔️, we bring the excitement of tech learning right into your home with our virtual classes. Our mission? To make tech learning fun and personal! Think of us as your remote tech guides 🚀. We try to find each individuals unique spark and help them explore the amazing world of technology."
      />
      <Instructors/>
      <Reviews/>
      <OurApproach />
      <ContactUs />
      <FAQ />
    </div>
  );
};

export default AboutUs;
