import React from "react";
import styles from "../../../pages/styles";
import { farcloudsourapproach, laptopzoom, technologyimage } from "../../../assets/images";

const OurApproach = () => {
  return (
    <section id="ourapproach" className="bg-whiteprimary pb-12 md:py-20">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row items-start justify-between">
          {/* Text Section */}

          {/* Image Section */}
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center">
            <img
              src={technologyimage}
              alt="Technology Image"
              className="max-w-sm w-full"
            />
          </div>

          <div className="md:w-1/2 md:pr-8">
            <h2 className={`${styles.headingdark} mb-4`}>
              Our Approach
            </h2>
            <p className={`${styles.paragraph} mb-6`}>
              Teaching with Technology to Spark the Curiosity to Learn
            </p>
            <ul className="space-y-4">
              <li className={`${styles.paragraph} bg-white rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>👩‍🏫</span>
                <span>Dedicated Expert Instructors</span>
              </li>
              <li className={`${styles.paragraph} bg-white rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>🌍</span>
                <span>Inclusive Tech Education for Kids of All Abilities</span>
              </li>
              <li className={`${styles.paragraph} bg-white rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>🕒</span>
                <span>Flexible Scheduling for Busy Families</span>
              </li>
              <li className={`${styles.paragraph} bg-white text-black rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>👩‍💻</span>
                <span>Interactive, Virtual Classes</span>
              </li>
              <li className={`${styles.paragraph} bg-white text-black rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>🧠</span>
                <span>STEAM Learning & Social Skills</span>
              </li>
              <li className={`${styles.paragraph} bg-white text-black rounded-lg p-3 shadow-md flex items-center gap-2`}>
                <span>🎉🎮</span>
                <span>Fun, Hands-On Learning with Every Session!</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurApproach;
