import React from "react";
import {
  AboutClasses,
  HeroClasses,
  SkillsClasses,
  TrialBar,
  TechRequiredClasses,
  PricingClasses,
  FAQ,
} from "../../components";
import { aboutcoding, coding, codinghero } from "../../assets/images";
import { Helmet } from "react-helmet";

const CodingClass = () => {
  return (
    <div>
      <Helmet>
        <title>
          Coding Classes for Kids: Turn Gaming into Learning | Tech Tails
        </title>
        <meta
          name="description"
          content="Transform screen time into skill-building with our fun, interactive coding classes! Kids learn to code through game creation, problem-solving, and hands-on projects."
        />
        <meta
          name="keywords"
          content="coding classes for kids, learn to code, game development for kids, programming courses, STEM education, coding education, interactive tech learning, Tech Tails coding"
        />
      </Helmet>

      <HeroClasses
        title="Coding"
        tagline="Replace hours of gaming with valuable learning"
        desc="🔄 Switch Up Playtime for Learning Adventures: 🎮➡️📚 Trade hours of gaming for invaluable learning experiences, where each session is a step towards mastering new skills 🛠️ and uncovering the exciting world of coding and technology!"
        img={codinghero}
      />
      <AboutClasses
        title="About Our Coding Class"
        desc="Our coding classes are designed to capture your child's interest and channel their love for games into valuable coding skills. 🎮 It's not just about playing games; it's about creating, exploring, and mastering the art of coding in a fun and interactive way. 🚀 Let's replace screen time with creative, skill-building adventures! 👾"
        image={aboutcoding}
      />
      <SkillsClasses
        title="Level Up Your Child's Skills"
        desc="Boost your kid's skills in a fun and cool way with our coding class!"
      />
      <PricingClasses
        title="Private 1:1 Pricing"
        desc="🚀 Our pricing is discounted as we enter the early stages of welcoming new students beyond friends and family."
      />
      <FAQ />
      <TechRequiredClasses />
    </div>
  );
};

export default CodingClass;
