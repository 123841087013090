import React from 'react'
import { FAQ, FreeTrialAbout, FreeTrialHero, FreeTrialPrograms, FreeTrialSkills, TryBefore } from '../../../components'
import {
  freetrialhero,
  freetrialheromobile,
  teacherzoom,
  textlogo,
  UFAlogo,
  ESAlogo,
} from '../../../assets/images'
import styles, { elements } from '../../styles'
import { TypeAnimation } from 'react-type-animation'
import { X } from 'lucide-react'
import InstructorExperience from '../InstructorExperience'
import StandaloneTrialForm from '../StandaloneTrialForm'
import ReviewsCompact from '../../../components/Home/layout/ReviewsCompact'

const ESA = () => {
  return (
    <div>
      {/* 
        Main layout:
        - flex-col on mobile (stacks)
        - flex-row on desktop (side by side)
      */}
      <div className="flex flex-col md:flex-row items-stretch w-full rounded-t-xl relative -mt-4 md:-mt-14">
        
        {/* LEFT SECTION (Hero Images) */}
        <div className="relative w-full md:w-1/2">
          {/* Desktop hero (hidden on mobile) */}
          <img
            src={freetrialhero}
            alt="Free Trial Hero (Desktop)"
            className="
              hidden 
              md:block 
              w-full 
              object-cover
              md:min-h-[700px]  /* enforce a decent desktop height */
            "
          />
          {/* Mobile hero (hidden on desktop) */}
          <img
            src={freetrialheromobile}
            alt="Free Trial Hero (Mobile)"
            className="
              block
              md:hidden
              w-full
              h-auto
              object-cover
            "
          />

          {/* Teacher Zoom (absolutely positioned to overlap) */}
          <img
            src={teacherzoom}
            alt="Teacher Zoom"
            className="
              teacherZoomImg
              zoomImg
              md:w-60
              w-40
              absolute              
              transform
            "
          />
        </div>

        {/* RIGHT SECTION (Logos, Heading, CTA) */}
        <div
          className="
            w-full 
            md:w-1/2
            px-4
            pb-6
            flex 
            flex-col 
            items-center 
            md:items-start 
            justify-center 
            text-center 
            md:text-left
          "
        >
          {/* Desktop-only Logos */}
          <div className="flex items-center py-6 space-x-2">
            <img
              src={textlogo}
              alt="Tech Tails Logo"
              className="
                w-auto 
                h-auto
                min-w-[100px]       /* change as needed */
                md:min-w-[100px]    /* scale up for md screens */
              "
            />
            <X 
              className="
                text-darkprimary 
                size-14 
                flex-shrink-0
              " 
            />
            <img
              src={ESAlogo}
              alt="Empowerment Scholarship Fund Logo"
              className="
                w-auto 
                h-auto
                min-w-[165px]      /* change as needed */
              "
            />
          </div>

          <h1 className={`${styles.biggerheadingdark} mb-4`}>
            Unlock Your Child's
            <br />
            <span className="underline decoration-pinkprimary decoration-[6px]">
              Potential
              <br />
            </span>
            In{" "}
            <TypeAnimation
              repeat={Infinity}
              speed={5}
              sequence={[
                "Technology",
                2000,
                "Programming",
                2000,
                "Creativity",
                2000,
                "Learning",
                2000,
                "Game Design",
                2000,
                "Coding",
                1500,
                "Digital Art",
                2000,
              ]}
            />
          </h1>

          <p className={`${styles.paragraph} mb-6`}>
            Replace hours of screen time with a valuable learning adventure
            making a video game! 🚀 Think of us as virtual technology guides
            👨‍💻
          </p>

          {/*
            CHANGED: Use an anchor link so that clicking it scrolls to the
            "Try Before Anything!" section below instead of going to /pages/freetrial
          */}
          <a
            className={`${elements.bluebutton} md:w-fit w-full`}
            href="#trialClass"
          >
            Request Trial Class
          </a>
        </div>
      </div>
      
      <InstructorExperience />
      <FreeTrialSkills />
      <ReviewsCompact/>
      <FreeTrialAbout />
      <FreeTrialPrograms />

      {/* 
        Wrap the Try Before Anything! section in a div with an id 
        that matches our anchor link above 
      */}
      <div
        id="trialClass"
        className="scroll-mt-20 flex flex-col py-14 gap-3 pt-12 md:px-40 px-4 items-center bg-darkprimary"
      >
        <h1 className={`${styles.biggerheadingwhite} text-center`}>
          Try Before{" "}
          <span className="underline decoration-pinkprimary decoration-8">
            Anything!
          </span>
        </h1>
        <p className={`${styles.paragraphwhite} text-center`}>
          We're confident if your child has an interest in anything tech related, they will love class. 
          Experience it with a free trial class, completely
          risk-free.
        </p>
        <StandaloneTrialForm/>
      </div>

      <FAQ/>
    </div>
  )
}

export default ESA
