import React, { useState } from "react";
import { Star, X } from "lucide-react";
import Marquee from "react-fast-marquee";
import styles from "../../../pages/styles";

const ReviewsCompact = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  
  const testimonials = [
    {
      name: "Kylee Eddington",
      text: "We are having such a great experience with Tech Tails! I can't say enough good about their instructors. Skyler and Jacob are amazing! My 11 yr old is taking video game design and has a opportunity to name and create his game his way! There's alot of texting and coding that he actually became a bit bored for like a minute lol Jacob had great suggestions and help my son get excited again. When he saw his game play he was so proud!! My son is growing and opening up after having such a difficult time last year in a different school. My 9 yr old is such a great story teller so she is being introduced to video editing this year. She is actually learning how to write her own series in a way that produces like a script. She wanted her friends to play a role in her series so she is going to cast them and hand them a script to learn the lines and act out their role in her mini movie! Skyler and Jacob both have great patience and advice on keeping the story on track. Shes excited to put on her own production. Tech tails allows us to do these classes on our time. Super supportive of time, abilities and super approachable and helpful! I have been recommending Tech Tails since classes started. Check them out!!! Voice what you want your kids to accomplish or be introduced to, they will have an answer and they will blow you away!",
    },
    {
        name: "Heidi Wray",
        text: "Our experience with Tech Tails has been fantastic! My first son enjoyed the challenge of building his roller coaster and appreciated how his teacher guided him through the process. Once he got the hang of it, he really had fun bringing his ideas to life. My daughter loves the one-on-one instruction, which has helped her learn to code, create her own characters, and even edit videos. She’s gained valuable skills in a way that feels exciting and creative. My second son enjoys his teacher and loves that he can design and play his own video games. It’s been a great way for him to develop his creativity and technical skills in an engaging way. Overall, Tech Tails has provided a great learning experience with supportive teachers who make the process enjoyable. Highly recommend!",
    },
    {
        name: "Amanda Ann Autrey",
        text: "My 6 year old son started with tech tails 4 months ago. It's my son's favorite class. He spends an hour once a week on zoom with his instructor Skyler. My son is super engaged in every lesson. The instructor really works with whatever level our child is at with everything. Our little guy isn't reading that well yet, but his instructor has helped work out systems and matches his level of understanding helping him find which buttons to press and is helping me learn the software to build games already. My son loves this creative outlet. He always ends class feeling confident and accomplished. I love it! Listening in on class the instructor is so smart and patient. It's a wonderful environment and really great for this tech gen we're raising. I recommend it! Worth every penny! My son has been playing with digital drawing to incorporate into his game designs! I'm always blown away by how smart this gen is and they need smart outlets like this. Highly recommended, planning to keep him going for the foreseeable future.",
    },
    {
        name: "Danielle Shettell",
        text: "My 10 year old son LOVES his Tech Tails class. His teacher Skyler is so patient and compassionate and works with him right on his level. It is one ability to know computer programming, another ability to teach it in person, and a completely different ability to teach this skill online to children. Skyler has all those abilities! I am very impressed and grateful. My son has learned so much and looks forward every week for classes. We will be continuing students for sure. Thanks Tech Tails!",
    },
    {
      name: "Kelli Fogelberg",
      text: "Tech Tails is fantastic! The one-on-one teaching really enables my son to learn exactly what he wants to be able to do and keeps him engaged so he can achieve so much. He has had a couple of different teachers as he has explored different skills, and every one has been attentive and helpful.",
    },
    {
      name: "Renae Beck",
      text: "At first we were hesitant to sign up but I’m so glad we did, my two kids 8 and 10 both love this so much, the teacher has been awesome at walking them through the process of game design and lets their creativity be the guide. Then teaches them how to create whatever they talk about he can figure out how to help them put it into the game.",
    },
  ];

  const allTestimonials = [...testimonials, ...testimonials, ...testimonials];

  return (
    <section className=" pb-6 md:pb-6 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="text-center mb-0">
          <p className={`${styles.paragraph}`}>
            Join families who've transformed their tech learning journey
          </p>
        </div>

        <Marquee
          speed={40}
          gradient={false}
          pauseOnHover
          play={!selectedTestimonial}
          direction="right"
          className="py-4"
        >
          {allTestimonials.map((testimonial, index) => (
            <div
              key={index}
              onClick={() => setSelectedTestimonial(testimonial)}
              className="w-[320px] mx-4 bg-white rounded-xl p-4 border  transition-all cursor-pointer h-[280px]"
            >
              <div className="flex items-center gap-2 mb-4">
                <Star className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
                <Star className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
                <Star className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
                <Star className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
                <Star className="w-5 h-5 fill-yellow-400 stroke-yellow-400" />
              </div>
              <div className="h-[160px] overflow-hidden">
                <p className={`${styles.paragraph} line-clamp-5`}>
                  {testimonial.text}
                </p>
              </div>
              <p className={`${styles.paragraph} font-semibold mt-4`}>
                {testimonial.name}
              </p>
            </div>
          ))}
        </Marquee>

        {selectedTestimonial && (
          <div
            className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4 backdrop-blur-sm"
            onClick={() => setSelectedTestimonial(null)}
          >
            <div
              className="bg-white rounded-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto p-6 relative border border-darkprimary"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setSelectedTestimonial(null)}
                className="absolute top-4 right-4 text-darkprimary hover:text-pinkprimary transition-colors"
              >
                <X size={24} />
              </button>
              <div className="flex items-center gap-2 mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className="w-5 h-5 fill-yellow-400 stroke-yellow-400"
                  />
                ))}
              </div>
              <p className={`${styles.paragraph} mb-4`}>
                {selectedTestimonial.text}
              </p>
              <p className={`${styles.paragraph} font-semibold`}>
                {selectedTestimonial.name}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ReviewsCompact;