import React from "react";
import {
  AboutClasses,
  HeroClasses,
  PricingClasses,
  SkillsClasses,
  TrialBar,
  TechRequiredClasses,
} from "../../components";
import { aboutgamedesign, coding, gamedesignhero } from "../../assets/images";
import { Helmet } from "react-helmet";

const GameDesignClass = () => {
  return (
    <div>
      <Helmet>
        <title>Game Design Classes: Personalized Tech Education from the Experts | Tech Tails</title>
        <meta
          name="description"
          content="Empower your child with the skills to create their own games! Tech Tails Game Design Classes offer a unique blend of creativity and technology, turning gamers into creators."
        />
        <meta
          property="og:title"
          content="Game Design Classes for Kids | TechTails"
        />
        <meta
          property="og:description"
          content="Dive into game development with Tech Tails! Learn storytelling, character creation, and coding in our interactive Game Design classes for kids. Start creating today!"
        />
      </Helmet>
      <HeroClasses
        title="Game Design"
        tagline="Replace hours of gaming with valuable learning"
        desc="🔄 Switch Up Playtime for Learning Adventures: 🎮➡️📚 Trade hours of gaming for invaluable learning experiences, where each session is a step towards mastering new skills 🛠️ and uncovering the exciting world of coding and technology!"
        img={gamedesignhero}
      />
      <AboutClasses
        title="About Our Game Design Class"
        desc="Step into our Game Design class! 🕹️ Here, your child will blend creativity with technology, learning how to build their own games from the ground up. It's not just playing games; it's about understanding the magic behind them, crafting stories, and bringing characters to life. 🎮👾 Each session is a fun, interactive step into the world of game development, sparking both imagination and tech skills!"
        image={aboutgamedesign}
      />
      <SkillsClasses
        title="Level Up Your Child's Skills"
        desc="Boost your kid's skills in a fun and cool way with our game design class!"
      />
      <PricingClasses
        title="Private 1:1 Pricing"
        desc="🚀 Our pricing is discounted as we enter the early stages of welcoming new students beyond friends and family."
      />
      <TechRequiredClasses />
    </div>
  );
};

export default GameDesignClass;
